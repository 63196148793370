export const getItem = key => {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        return null;
    }
};

export const setItem = (key, val) => {
    localStorage.setItem(key, JSON.stringify(val));
};

export const removeItem = (key) => {
    localStorage.removeItem(key);
};

export const isLocalhost = () => {
    const host = getHostname();
    return host.startsWith('localhost') || host.includes('192.168');
}

export const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
}

export const getHostname = () => {
    return String(window.location.host).split(':')[0];
}

export const downloadData = (data, filename) => {
    console.log(data);
    if(data.length===0) {
        return;
    }
    const labels = Object.keys(data[0]);
    data = data.map(d=>Object.values(d));
    data.unshift(labels);
    
    const processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    let csvFile = '';
    for(const row of data) {
        csvFile += processRow(row);
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename || 'export.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const toJson = (obj) => {
    if(obj && typeof obj === 'object') {
        return obj;
    }
    try {
        obj = JSON.parse(obj);
    }
    catch(error) {
        obj = {};
    }

    return obj;
}
export const toObject = toJson;

export const toArray = (arr) => {
    return Array.isArray(arr) ? arr : [];
}

export const renderValue = (value) => {

    switch (typeof value) {
        case 'boolean':
            return value === true ? 'true' : 'false';
        case 'object':
            return value ? JSON.stringify(value) : '';
        case 'string':
            return value || '';
        default:
            return value;
    }
}

export const textToTitle = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getFields = (opts) => {
    const fields = [];

    for(const opt of opts) {

        let key, label, type='text';

        if(typeof opt === 'string') {
            key = opt;
            label = textToTitle(opt);
        }
        else {
            key = opt.key;
            label = opt.label || key;
            if(opt.type) { type = opt.type; }
        }

        const field = {
            key, 
            label, 
            type,
            ...opt,
        };

        if(type === 'select' || Array.isArray(opt?.opts)) {
            field.type = 'select';
            field.opts = [];
            for(const o of Array.isArray(opt?.opts) ? opt.opts : []) {
                if(typeof o === 'string') {
                    field.opts.push({value:o, text:o});
                }
                else {
                    field.opts.push(o);
                }
            }
        }

        fields.push(field);
    }


    return fields;
}

export const sleep = async (ts) => new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, ts || 3000); });
import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function WorkingComponent({ text }) {

    text = text || 'loading'

    return (
        <div align="center"><Spinner animation="border" /> <br /> {text}...</div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { Amplify, Auth } from 'aws-amplify';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Provider } from 'react-redux';
import Store from './core/store';

import { getClientId } from './core/config';

Amplify.configure({
  Auth: {
    identityPoolId: 'eu-west-2:07717b7d-d98e-41c4-9cfd-e080c6b1d73a',
    region: 'eu-west-2', 
    userPoolId: 'eu-west-2_2YuOkZtxu', 
    userPoolWebClientId: getClientId(),
  },
  API: {
    endpoints: [
      { 
        name:'api', 
        endpoint:'https://8jhlwypvmj.execute-api.eu-west-2.amazonaws.com', 
        custom_header: async () => { return { Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`}; }, 
      }
    ]
  },
});

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <AmplifyProvider>
        <App />
      </AmplifyProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);



import { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import WorkingComponent from './ui/working';

import { getAppRef, cmsUrl, isCms, getApp, getUrl } from './core/config';

const appRef = getAppRef();
const app = getApp();
window.document.title = `${app.name} App`;

const PuraflexComponent = lazy(()=> import('./components/puraflex/puraflex'));
const PowerbaseComponent = lazy(()=> import('./components/powerbase/powerbase'));
const CmsComponent = lazy(()=> import('./components/cms/cms'));

const AppLoader = () => {
  const [componentKey, setComponentKey] = useState('');

  const location = useLocation();

  const { pathname } = location;

  const handleChangedPath = () => {
    const isCmsPath = pathname.startsWith(cmsUrl());

    if(isCmsPath && isCms()) {
      setComponentKey('cms');
      return;
    }

    if(!isCmsPath && !isCms()) {
      setComponentKey(appRef);
      return;
    }

    if(isCmsPath) {
      isCms(true);
      window.location = cmsUrl();
    }
    else {
      isCms(false);
      window.location = getUrl();
    }

  };

  useEffect(()=>{ handleChangedPath(); }, [pathname]);
  useEffect(()=>{ handleChangedPath(); }, []);

  return (
    <Suspense fallback={<div style={{margin:'0 auto'}}><WorkingComponent /></div>}>
      {componentKey === 'cms' && <CmsComponent />}
      {componentKey === 'puraflex' && <PuraflexComponent />}
      {componentKey === 'powerbase' && <PowerbaseComponent />}
    </Suspense>
  );
};

const AppComponent = () => {

  const [loaded, setLoaded] = useState(false);

  const init = async() => {

    setLoaded(true);
  }

  useEffect(()=>{ init(); }, []);

  if(!loaded) {
    return (<WorkingComponent />);
  }

  return (
    <Router>
      <AppLoader />
    </Router>
  );
};

export default AppComponent;

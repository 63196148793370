import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { combineReducers } from 'redux';

const defaultData = {};
export const dataReducer = (state = defaultData, action) => {
    switch (action.type) {
        case 'DATA_SET':
            return { ...defaultData, ...action.payload };
        case 'DATA_RESET':
            return defaultData;
        default:
            return state;
    }
}

const defaultAuth = null;
export const authReducer = (state = defaultAuth, action) => {
    switch (action.type) {
        case 'AUTH_SET':
            return { ...defaultAuth, ...action.payload };
        case 'AUTH_RESET':
            return defaultAuth;
        default:
            return state;
    }
}

const defaultMe = null;
export const meReducer = (state = defaultMe, action) => {
    switch (action.type) {
        case 'ME_SET':
            return { ...defaultMe, ...action.payload };
        case 'ME_REST':
            return defaultMe;
        default:
            return state;
    }
}

const middleware = [thunk];

const Store = createStore(combineReducers({
    auth: authReducer,
    data: dataReducer,
    me: meReducer,
}), applyMiddleware(...middleware));

export default Store;
import { getItem, setItem, isLocalhost } from './utils';

const defaultAppRef = 'puraflex';
const apps = {
    'lakeland': {name: 'Lakeland Permasure', clientId:'3sdk49gohumhoihomlbnc698iv'},
    'respirex': {name: 'Respirex Permasure', clientId:'721phtf4bl75f53mnkl935138p'},
    'itp': {name: 'ITP Permasure', clientId:'1e076rcsbicjc9lo8lbruvn0vi'},
    'puraflex': {name: 'Puraflex', clientId:'16s3hjspq3d2t6rv87e17klc0m', path:'/puraflex'},
    'powerbase': {name: 'Powerbase', clientId:'212arck2rt0pkhgidebq47h02d', path:'/powerbasevoc'},
};

if(isLocalhost()) {
    for(const ref in apps) {
        apps[ref].path = '';
    }
}

export const getApps = () => apps;
export const getApp = (ref) => {
    ref = ref || getAppRef();
    const app = apps[ref];
    return app || apps[defaultAppRef];
}

export const getAppRef = () => {
    const ref = getItem('app-ref');
    return ref || defaultAppRef;
}

export const setAppRef = (ref) => {
    const refs = Object.keys(apps);
    if(!refs.includes(ref)) {
        ref = defaultAppRef;
    }
    setItem('app-ref', ref);
}

export const getAppName = () => {
    const app = getApp();
    return app.name;
}

export const getClientId = (ref) => {
    ref = ref || getAppRef();
    const apps = getApps();
    const app = apps[ref] || apps[defaultAppRef];
    return app?.clientId;
}

const components = {
    'chemicals': {title:'Chemicals', url:'/chemicals', info:'All Chemicals', menu: true, dashboard:true, admin:true},
    'users': {title:'Users', url:'/users', info:'All users', menu: true, dashboard:true, admin:true},
    'products': {title:'Products', url:'/products', menu: true, dashboard:true, admin:true},
    'hazards': {title:'Hazards', url:'/hazards', menu: true, dashboard:true, admin:true},
    'app': {title:'App', url:'/app', menu: true, dashboard:true},
    // 'dashboard': {title:'Dashboard', url:'/'},
    'account': {title:'Account', url:'/account', menu: true, dashboard:true},
    'home': {title:'Dashboard', url:'/'},
};

export const getComponents = () => components;

export const cmsUrl = (path) => {
    const app = getApp();
    const base = `${app.path}/cms`;
    return path ? `${base}${path}` : base;
}

export const getUrl = (path) => {
    const app = getApp();
    const base = `${app.path}/`;
    return path ? `${base}${path}` : base;
}

const cmsComponents = [
    // {title:'Chemicals', url:cmsUrl('/chemicals'), info:'All Chemicals', menu: true, dashboard:true, admin:true},
    {title:'Users', url:cmsUrl('/users'), info:'All users', menu: true, dashboard:true, admin:true},
    // {title:'Products', url:cmsUrl('/products'), menu: true, dashboard:true, admin:true},
    // {title:'Hazards', url:cmsUrl('/hazards'), menu: true, dashboard:true, admin:true},
    // {title:'App', url:'/app', menu: true, dashboard:true},
    // {title:'Dashboard', url:'/'},
    // {title:'Account', url:'/account', menu: true, dashboard:true},
    // {title:'Dashboard', url:'/'},
];
export const getCmsComponents = (permissions) => {
    permissions = permissions || [];
    const isAdmin = permissions.includes('admin');
    const isManager = permissions.includes('manager');
    return cmsComponents;
}

export const isCms = (flag) => {
    const currentFlag = getItem('is-cms');
    if(flag === undefined) {
        return currentFlag;
    }
    setItem('is-cms', !!flag);
}
